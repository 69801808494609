<template>
  <div class="white">
    <div
      class="d-flex justify-space-between align-center pr-14 pl-14 pt-8 pb-8"
    >
      <h1 class="primary--text text-h6 font-weight-black">
        DETALLES DE PLAN DE INTERNET
      </h1>
      <v-btn
        class="pl-6 pr-6"
        outlined
        color="primary"
        @click="$emit('changeComponent', 'InternetPlansTable')"
      >
        REGRESAR
      </v-btn>
    </div>
    <v-divider></v-divider>
    <v-row class="pr-14 pl-14 pt-8 pb-8">
      <v-col cols="1">
        <v-img
          max-width="30"
          src="@/assets/icons/icon-internet-home.svg"
        ></v-img>
      </v-col>
      <v-col cols="6">
        <div class="mb-6">
          <p class="text-subtitle-2 font-weight-regular mb-2">Nombre</p>
          <p class="pa-0 text-uppercase font-weight-black">{{ item.name }}</p>
        </div>
        <div class="mb-6">
          <p class="text-subtitle-2 font-weight-regular mb-2">Descripción</p>
          <p class="pa-0 text-uppercase font-weight-black">
            {{ item.description }}
          </p>
        </div>
        <div class="mb-6">
          <p class="text-subtitle-2 font-weight-regular mb-2">
            Velocidad en MB
          </p>
          <p class="pa-0 text-uppercase font-weight-black">{{ item.speed }}</p>
        </div>
        <div class="mb-6">
          <p class="text-subtitle-2 font-weight-regular mb-2">Precio mensual</p>
          <p class="pa-0 text-uppercase font-weight-black">
            {{ getFormatPrice(item.price) }}
          </p>
        </div>
      </v-col>
      <v-col cols="5" class="d-flex flex-row-reverse">
        <div>
          <p class="text-center text-subtitle-2 font-weight-regular mb-2">
            Número de suscriptores
          </p>
          <p class="pa-0 text-uppercase font-weight-black">
            {{ item.subscribers }} subscriptores
          </p>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex flex-row-reverse">
        <v-btn depressed color="primary" @click="$emit('edit', item)"
          >EDITAR PLAN</v-btn
        >
        <v-btn
          class="mr-5"
          depressed
          color="primary"
          @click="dialogDelete = true"
          >ELIMINAR PLAN</v-btn
        >
      </v-col>
    </v-row>

    <modal-delete
      v-if="dialogDelete"
      :dialogDelete="dialogDelete"
      :body="body"
      @cancel="dialogDelete = false"
      @delete="deleted"
    ></modal-delete>
  </div>
</template>

<script>
// Mixins
import { FormatsMixin } from "../../mixins/Formats";

// Domains
import Plans from "../../domain/Plans";

export default {
  name: "InternetPlansDetails",
  mixins: [FormatsMixin],
  components: {
    ModalDelete: () => import("../base/ModalDelete"),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialogDelete: false,
    body: {
      title: "ELIMINAR PLAN DE INTERNET",
      type: "PLAN",
      question:
        "¿Esta seguro que quiere eliminar este plan de internet, una vez confirmada esta acción ya no se puede deshacer?",
    },
  }),
  methods: {
    async deleted() {
      try {
        await Plans.delete(this.item.code);
        this.$emit("changeComponent", "InternetPlansTable");
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>